:root {
  --one:   #e7e6e1; 
  --two:   #f7f6e7; 
  --white: #ffffff;
  --black: #000000;
}

/* Tag styling */
body {
  margin: 0;
  background-color: var(--white);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1200px;
}

div {
  border-radius: 5px;
  gap: 10px;
}

form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;  margin: 5px;
  display:flex;
  flex-direction: column;
}

input {
  margin: 5px;
  padding: 5px;
}

input[type='file']::file-selector-button {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;  margin: 5px;
  margin: 5px;
  padding: 5px;
}

/* Class Styling */
.content {
  display: flex;
  flex-direction: column;
  flex: 1 100%;
  margin:5px;
}

.createAccount {
  border-style: none;
  background: none;
  color: blue;
  margin: 5px;
  padding: 5px
}

.createAccount:hover {
  cursor: pointer;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;  margin: 5px;
}

.errorMessage {
  background-color: #ff0000;
  color: #ffffff;
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.fileList {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  margin: 5px;
  display: flex;
  flex-direction: column;
  margin:5px;
  margin-bottom: 50px;
  min-width: 300px;
}

.fileCard {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;  margin: 5px;
  border: 0px;
  cursor: pointer;
  padding: 5px;
}

.fileCard:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;  margin: 5px;
}

.fileUpload {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  margin: 5px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  margin-top: 30px;
  padding: 10px;
  min-width: 300px;
}

.login {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  margin: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding:10px
}

.sideBar {
  display: flex;
  flex-direction: row;
  flex: 1 100%;
  margin:5px;
}

.signUp {
  margin: auto;
  width: 320px;
}

.signUpLink {
  display: block;
  text-align: center;
}

.submit {
  margin: 5px;
  padding: 5px;
}

.titleBar {
  background-image: url('/public/images/pps.png');
  background-size: 100%;
  font-size: larger;
  font-weight: bold;
  margin: 10px;
  padding: 60px;
  text-align: center;
}

.titleCard {
  font-weight: 600;
  font-size: large;
  margin: 5px;
  padding:5px;
  text-align: center;
}

.uploadCard {
  cursor: pointer;
  margin: 5px;
  padding: 5px;
}

.uploadCard:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;  margin: 5px;
}

.webGL {
  aspect-ratio: 1/1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  margin: 5px;
  margin:5px;
  min-width: 800px;
  max-width: 100%;
}

